class Misc{

  constructor(){

    this.snackSwitch = this.snackSwitch.bind( this );

    this.mods = {
      snack: document.querySelector( '.mod-snack' )
    };

    this.els = {
      switcher: this.mods.snack && this.mods.snack.querySelectorAll( '[data-switcher]' ),
      ofit: document.querySelectorAll( '.ofit' )
    };

    if( typeof CSS == 'undefined' || ( CSS && !CSS.supports ) || ( CSS.supports && !CSS.supports( 'object-fit', 'cover' ))){

      this.objfit = true;

      if( !document.body.classList.contains( 'page-contact' )){
        document.documentElement.classList.add( 'ofitted' );
      }

    }

    if( this.els.switcher ){
      this.mods.snack.addEventListener( 'change', this.snackSwitch );
    }

    if( this.objfit && this.els.ofit ){
      this.ofit();
    }

  }

  ofit(){

    [].slice.call( this.els.ofit ).forEach(( elt ) => {

      const parent = elt.parentNode.tagName.toLowerCase() !== 'picture' ? elt.parentNode : elt.parentNode.parentNode;

      parent.classList.add( 'object-fit' );
      parent.style.backgroundImage = `url(${elt.src})`;

    });

  }

  snackSwitch( e ){

    if( !e.target.closest( '[data-switcher]' )){
      return;
    }

    const activeId = `${e.target.id}`;

    [].slice.call( this.mods.snack.querySelectorAll( `.tabbable:not([id*="${activeId}-"])` )).forEach(( elt ) => {
      this.mods.snack.classList.remove( `active-${elt.id.replace( /-(block|title)/, '' )}` );
      elt.classList.add( 'inactive' );
    });
    [].slice.call( this.mods.snack.querySelectorAll( `.tabbable[id*="${activeId}-"]` )).forEach(( elt ) => {
      this.mods.snack.classList.add( `active-${activeId}` );
      elt.classList.remove( 'inactive' );
    });

  }

}

export default new Misc();
