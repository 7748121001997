import Validator from '@switch-company/form-validation';

const params = {
  customRules: [
    {
      match: '[data-maxlength], [maxlength]',
      test: el => {
        var length = +( el.dataset.maxlength || el.getAttribute( 'maxlength' ));

        return el.value.length <= length ? true : 'tooLong';
      }
    }
  ]
};

class Form {

  constructor() {

    this.toggle = this.toggle.bind( this );
    this.check = this.check.bind( this );
    this.uploadDisplay = this.uploadDisplay.bind( this );

    this.els = {
      selectors: document.querySelectorAll( '.selector' ),
      uploaders: document.querySelectorAll( 'input[type="file"]' ),
      forms: document.querySelectorAll( 'form' ),
      formsSubmitters: document.querySelectorAll( '[type="submit"]' ),
      inputs: document.querySelectorAll( '[data-custommessage]' ),
      countdowns: document.querySelectorAll( '[data-countdown]' )
    };

    [].slice.call( this.els.selectors ).forEach(( selector ) => {
      selector.addEventListener( 'change', this.select );

      if( selector.hasAttribute( 'data-selector' )){
        selector.addEventListener( 'change', this.toggle );
      }
    });

    [].slice.call( this.els.uploaders ).forEach(( uploader ) => {
      uploader.addEventListener( 'change', this.uploadDisplay );
    });

    // if( HTMLFormElement.prototype.reportValidity ){
    //   [].slice.call( this.els.forms ).forEach(( form ) => {
    //     form.addEventListener( 'submit', this.check );
    //   });

    //   [].slice.call( this.els.formsSubmitters ).forEach(( formsSubmitter ) => {
    //     formsSubmitter.addEventListener( 'click', this.check );
    //   });
    // }
    [].slice.call( this.els.forms ).forEach(( form ) => {
      if( HTMLFormElement.prototype.reportValidity ){
        form.addEventListener( 'submit', this.check );
      }
      else{
        form.addEventListener( 'submit', () => {
          this.checkCaptcha( form.querySelector( '.g-recaptcha' ));
        });
      }
    });

    [].slice.call( this.els.formsSubmitters ).forEach(( formsSubmitter ) => {
      if( HTMLFormElement.prototype.reportValidity ){
        formsSubmitter.addEventListener( 'click', this.check );
      }
      else{
        formsSubmitter.addEventListener( 'click', () => {
          this.checkCaptcha( formsSubmitter.closest( 'form' ).querySelector( '.g-recaptcha' ));
        });
      }
    });

    [].slice.call( this.els.inputs ).forEach(( input ) => {
      input.setCustomValidity( input.dataset.custommessage );
    });

    [].slice.call( this.els.countdowns ).forEach(( countdown ) => {

      if( countdown.textContent.indexOf( '${' ) != -1 ){
        countdown.textContent.match( /\$\{([0-9]+)\}/ )[ 0 ];
        countdown.dataset.countdown = RegExp.$1;
        countdown.innerHTML = countdown.textContent.replace( '${', '<span data-count>' ).replace( '}', '</span>' );
      }
      else{
        countdown.dataset.countdown = countdown.textContent.match( /[0-9]+/ )[ 0 ];
        countdown.innerHTML = countdown.textContent.replace( /([0-9]+)/, '<span data-count>$1</span>' );
      }
      countdown.closest( '.form-field' ).querySelector( 'textarea' ).addEventListener( 'input', this.countdown );
    });

  }

  select( e ){

    const trigger = e.currentTarget,
      label = trigger.parentNode.querySelector( 'label' );

    label.textContent = trigger.querySelectorAll( `option[value="${trigger.value}"]` )[ 0 ].textContent;

  }

  uploadDisplay( e ){

    const trigger = e.currentTarget,
      label = trigger.nextElementSibling,
      fileInfos = trigger.files[ 0 ];

    let txt;

    if( fileInfos ){
      txt = fileInfos.name;
      trigger.classList.add( 'filled' );
    }
    else{
      txt = trigger.dataset.label;
      trigger.classList.remove( 'filled' );
    }
    label.textContent = txt;


    // console.log(trigger.files[0].size/1024);


  }

  toggle( e ) {

    const selector = e.currentTarget,
      target = document.getElementById( selector.value ),
      visibleSiblings = selector.closest( '.wp' ).querySelector( '[aria-hidden="false"]' );

    if( visibleSiblings ){
      visibleSiblings.setAttribute( 'aria-hidden', 'true' );

    }
    if( target ){
      target.setAttribute( 'aria-hidden', 'false' );
    }

  }

  countdown( e ){

    const trigger = e.currentTarget,
      target = trigger.closest( '.form-field' ).querySelector( '[data-count]' ),
      count = +target.parentNode.dataset.countdown;

    target.textContent = count - trigger.value.length;

  }

  checkCaptcha( captcha ){
    if( captcha ){
      captcha.nextElementSibling.checked = window.grecaptcha ? ( window.grecaptcha.getResponse( captcha.dataset.id ) != '' ) : true;
    }
  }

  scrollError( highest ){
    window.scrollTo( 0, highest - document.querySelector( '.nav-wrapper' ).offsetHeight - 40 );
  }

  check( e ) {

    const target = e.currentTarget.closest( 'form' ) || e.currentTarget,
      validator = new Validator( target, params ),
      captcha = target.querySelector( '.g-recaptcha[id^="recaptcha"]' );

    // cleanup previous
    // [].slice.call( target.querySelectorAll( '.form-field.error' )).forEach(( field ) => {
    //   field.classList.remove( 'error' );
    //   field.querySelector( '.error-message' ).parentNode.removeChild( field.querySelector( '.error-message' ).parentNode.querySelector( '.error-message' ));
    // });

    this.checkCaptcha( captcha );

    if( !validator.checkValidity()){
      e.preventDefault();

      let i = 0,
        highest = validator.invalid[ 0 ].offsetTop;


      [].slice.call( target.querySelectorAll( '[data-custommessage]' )).forEach(( input ) => {
        input.setCustomValidity( '' );
      });

      for( ; i < validator.invalid.length; i++ ){

        if( validator.invalid[ i ].dataset.custommessage ){
          validator.invalid[ i ].setCustomValidity( validator.invalid[ i ].dataset.custommessage );
        }

        // const input = validator.invalid[ i ],
        //   field = input.closest( '.form-field' ),
        //   errorMsg = field.querySelector( '.error-message' );

        // if( !errorMsg ){
        //   field.insertAdjacentHTML( 'beforeend', `<p class="error-message">${input.dataset.errormsg || input.validationMessage}</p>` );
        // }
        // field.classList.add( 'error' );

        highest = validator.invalid[ i ].offsetTop < highest ? validator.invalid[ i ].offsetTop : highest;
        this.scrollError( highest );
        validator.invalid[ i ].reportValidity();
        break;

      }


      // if( 'scrollBehavior' in document.documentElement.style ){
      //   window.scrollTo({
      //     top: highest - document.querySelector( '.nav-wrapper' ).offsetHeight - 40,
      //     behavior: 'smooth'
      //   });
      // }

    }

  }

}

export default ( function(){
  const form = document.querySelector( '.form form' );

  if( form ){
    return new Form();
  }
})();
