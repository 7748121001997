// polyfils
import './polyfills/customEvent.js';
import './polyfills/closest.js';
import './polyfills/object-assign.js';
import './polyfills/array-from.js';
import KB from '@switch-company/keyboard-navigation';

new KB();

// Helpers
import './helpers/expand-toggle';

// Libs
import './modules/cookie';
import './modules/nav';

import './modules/gamme-misc';
import './modules/gamme-form';

window.captchaCallback = function() {
  [].slice.call( document.querySelectorAll( '.g-recaptcha[id^="recaptcha"]' )).forEach(( captcha ) => {
    captcha.dataset.id = window.grecaptcha.render( captcha );
  });
};


$(document).ready(function() {

  $(window).on('load', function(){

    // Accordions
    console.log('Accordions');
    $('.js-has-accordions-list').each(function () {
      $(this).find('.accordion--trigger').on('click', function () {
        $(this).closest('li').siblings('li').find('.accordion--trigger').removeClass('is-expanded').siblings('.accordion--dropdown').stop().slideUp('fast');
        $(this).toggleClass('is-expanded').siblings('.accordion--dropdown').stop().slideToggle('fast');
      });
    });
  });
});
