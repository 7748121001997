import Cookies from 'js-cookie';

class Cookie{
  constructor(){
    this.el = document.querySelector( '.cookie' );

    this.close = this.close.bind( this );
    this.remove = this.remove.bind( this );

    if( !this.el ){
      return;
    }

    this.check();
  }

  check(){
    if( !Cookies.get( 'cookie_read' ) || !Cookies.get( 'cookie_msg_display' )){
      // session cookie
      Cookies.set( 'cookie_msg_display', true );

      this.el.classList.add( 'show' );

      this.el.addEventListener( 'click', this.close );
      this.el.addEventListener( 'transitionend', this.remove );
    }
    else{
      this.el.parentElement.removeChild( this.el );
      this.writeAnalytics();
    }
  }

  close( event ){
    const button = event.target.closest( '.cookie-close' );

    if( !button ){
      return;
    }

    Cookies.set( 'cookie_read', true, { expires: 365 });

    this.el.classList.remove( 'show' );
  }

  remove( event ){
    if( event.target !== this.el || this.el.classList.contains( 'show' )){
      return;
    }

    this.el.parentElement.removeChild( this.el );

    this.writeAnalytics();
  }

  writeAnalytics(){
    const script = document.createElement( 'script' );

    window.dataLayer = window.dataLayer || [];

    window.gtag = function gtag(){
      window.dataLayer.push( arguments );
    };

    window.gtag( 'js', new Date());

    window.gtag( 'config', 'UA-20284670-11' );

    script.setAttribute( 'async', 'true' );
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-20284670-11';

    document.head.appendChild( script );
  }
}

export default new Cookie();
